.profile {
  width: 100%;
  background-color: white;
  height: 300px;
  text-align: center;
  padding-bottom: 2vh;
  border-bottom: 1px solid #eeeeee;
}
.profile svg {
  text-align: center;
  height: 70%;
  padding: 1vh;
  background-color: white;
  margin-top: 2vh;
}
.profile .welcome {
  font-size: 1.5vh;
  line-height: 1.5;
  font-weight: 600;
  color: #949494;
}
