.header {
  background-color: #ffffff;
  border-bottom: 1px solid #c7c7c7;
}

.header .headtitle {
  font-size: 2vh;
  padding-left: 1vh;
  color: #31e918;
}

.logo {
  height: 80%;
}
